@import '../../shared/styles/variables.scss';

.sliderStocks {
  width: 100%;
  display: flex;
  padding: 1em;
  justify-content: center;

  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: 90%;
  --swiper-navigation-sides-offset: 3%;
  --swiper-navigation-color: #2d993a;

  @media (max-width: $desktop-width) {
    padding: 0;
  }
}
