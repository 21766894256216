@import '../../shared/styles/variables.scss';

.navigationList {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;

  @media (max-width: $tablet-width) {
    flex-direction: column;
    gap: 1.5em;
    width: 100%;
    align-items: flex-start;
  }
}

.navigationList__item {
  // padding: 10px 15px;
  // border-radius: 10px;
  // transition: 0.2s;

  a {
    @extend %link;
    cursor: pointer;
  }

  // @media (max-width: 900px) {
  //   padding: 5px 1vw;
  //   font-size: 0.95rem;
  // }

  @media (max-width: $tablet-width) {
    width: 100%;
    text-align: end;

    a {
      display: block;
      width: 100%;
      font-size: 1.2rem;
    }
  }

  // @media (min-width: $tablet-width) {
  //   &:hover {
  //     transition: 0.2s;
  //     background-color: $block-color;
  //   }
  // }
}
