@import '../../shared/styles/variables.scss';

.services__section {
  width: 80%;
  margin-bottom: 5em;
  position: relative;

  @media (max-width: 900px) {
    margin-top: 1em;
  }

  @media (max-width: $tablet-width) {
    margin-top: 9em;
  }
}

.services__section_global {
  .servicesList {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;

    @media (max-width: $tablet-width) {
      grid-template-columns: 1fr;
    }
  }
}

.servicesList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 1em;
  row-gap: 3em;
  width: 100%;

  @media (max-width: $desktop-width) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
}

.servicesList {
  list-style: none;

  a {
    text-decoration: none;
  }
}
