@import '../../shared/styles/variables.scss';

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  z-index: 5;
}

.header__header {
  font-family: 'Marck Script', cursive;
  font-size: 2.5em;
  font-weight: lighter;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: $green-color;
  }
}

.header__logoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 1em 0;
  z-index: 5;

  &:hover {
    svg {
      fill: $green-color !important;
    }
  }
}

.burgerBtn__openWrapper {
  display: none;

  @media (max-width: $tablet-width) {
    display: block;
    position: absolute;
    top: 10px;
    left: 250px;
    cursor: pointer;
    z-index: 10;
  }
}

.header__nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: $tablet-width) {
    background-color: $green-color;
    background-color: rgba(45, 153, 58, 0.75);
    backdrop-filter: blur(5px);
    box-shadow: 0 0 20px 3px $gray-color;
    min-width: 725px;
    padding: 0 0 1em 2em;
    display: flex;
    border-bottom-left-radius: 15px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2050;
    transform: translateX(110%);
    transition: 0.3s;

    &_open {
      transform: translateX(58%);
    }
  }
}

.header__logo {
  width: 15%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  height: min-content;
  z-index: 5;

  svg {
    position: relative;
    left: -1em;
    width: 20vw;
    height: 100%;
    min-width: 150px;

    @media (max-width: $tablet-width) {
      fill: $white-color;
      left: 0;

      &:hover {
        fill: $white-color;
      }
    }
  }

  &:hover {
    h2 {
      color: $green-color;
    }
  }

  @media (max-width: $tablet-width) {
    overflow: hidden;
    height: 100px;
    width: 35%;
    min-width: 113px;
  }
}

.header__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2em 1em 0 0;
  position: relative;

  @media (max-width: $tablet-width) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    gap: 2em;
    width: 37%;

    a {
      color: $white-color;

      &:hover {
        color: $white-color;
        text-shadow: 0 0 5px $white-color;
      }
    }

    .signBtn {
      font-size: 1rem;
    }
  }
}

.signBtn {
  @extend %main-action-btn;

  @media (max-width: $tablet-width) {
    background-color: $white-color;
    color: $text-color;

    svg {
      fill: $green-color;
    }

    &:hover {
      background-color: $green-color;
      box-shadow: inset 0 0 0 1px $white-color;
      color: $white-color;

      svg {
        fill: $white-color !important;
      }
    }
  }
}

.header__logoHeader {
  height: 175px;
  width: auto;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  svg {
    fill: $text-color;
  }
}

.header__logoWrapper_close {
  display: none;
}
