@import '../../shared/styles/variables.scss';

.faqBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.faqBlock__wrapper {
  background-color: $block-color;
  border-radius: 15px;
  padding: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

.faq__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 50%;
  padding: 1em;

  @media (max-width: $tablet-width) {
    width: 75%;
  }

  @media (max-width: $mobile-width) {
    width: 90%;
  }
}

.faq__header {
  width: 100%;
  text-align: center;
}
