@import '../../shared/styles/variables.scss';

.specialists__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  cursor: default;

  @media (max-width: 900px) {
    margin-top: 1em;
  }

  @media (max-width: $tablet-width) {
    margin-top: 8em;
  }

  @media (max-width: $mobile-width) {
    width: 90%;
  }
}

.specialistsList {
  list-style: none;
}

.specialistsList__item {
  border-bottom: 3px solid $green-color;
  margin-bottom: 4em;
  padding: 0 2em 2em 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.specialists__btn {
  @extend %main-action-btn;
  align-self: flex-end;
}
