@import '../../shared/styles/variables.scss';

.service__section {
  margin-top: -1em;

  @media (max-width: 900px) {
    margin-top: 1em;
  }

  @media (max-width: $tablet-width) {
    margin-top: 8em;
  }
}
