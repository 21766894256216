@import '../../shared/styles/variables.scss';

.advantages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 3em;
  font-size: 1.3vw;
  align-items: stretch;

  @media (min-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: $desktop-width) {
    grid-template-columns: 1fr 1fr;
    font-size: 0.8rem;
  }

  @media (max-width: $mobile-width) {
    grid-template-columns: 1fr;
    font-size: 1rem;
  }
}

.advantages__header {
  text-align: center;
  margin-bottom: 2em;
}
