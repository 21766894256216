.globalCard {
  max-height: 460px;
  max-width: 700px;
  min-height: 220px;
  min-width: 300px;
  width: 34vw;
  height: 23vw;

  img {
    max-height: 460px;
    max-width: 700px;
    min-height: 220px;
    min-width: 300px;
    width: 34vw;
    height: 23vw;
  }
}
