@import '../../shared/styles/variables.scss';

.whatsappLink {
  background-color: white;
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 5%;
  right: 3%;
  box-shadow: inset 0 0 7px $green-color;
  z-index: 10;
  animation: pulse 2s infinite;
  transition: 0.2s;
  border: 2px solid rgba(0, 128, 0, 0.4);
  max-width: 70px;
  min-height: 40px;
  min-width: 40px;

  * {
    width: 100%;
    height: 100%;
    min-width: 30px;
    min-height: 30px;

    @media (max-width: $desktop-width) {
      padding: 3px;
    }

    @media (max-width: $tablet-width) {
      min-width: 20px;
      min-height: 20px;
    }
  }

  &:hover {
    transition: 0.2s;
    background-color: $green-color;
    * {
      fill: $white-color;
    }
    border: 2px solid $white-color;
  }

  @media (max-width: $tablet-width) {
    box-shadow: inset 0 0 4px $green-color;
    animation: pulseMini 2s infinite;
    min-height: 30px;
    min-width: 30px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0.4),
      inset 0 0 10px $green-color;
  }
  70% {
    -webkit-box-shadow:
      0 0 0 13px rgba(0, 128, 0, 0),
      inset 0 0 10px $green-color;
  }
  100% {
    -webkit-box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0),
      inset 0 0 10px $green-color;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0.4),
      inset 0 0 10px $green-color;
    box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0.4),
      inset 0 0 10px $green-color;
  }
  70% {
    -moz-box-shadow:
      0 0 0 13px rgba(0, 128, 0, 0),
      inset 0 0 10px $green-color;
    box-shadow:
      0 0 0 13px rgba(0, 128, 0, 0),
      inset 0 0 10px $green-color;
  }
  100% {
    -moz-box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0),
      inset 0 0 10px $green-color;
    box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0),
      inset 0 0 10px $green-color;
  }
}

@-webkit-keyframes pulseMini {
  0% {
    -webkit-box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0.4),
      inset 0 0 4px $green-color;
  }
  70% {
    -webkit-box-shadow:
      0 0 0 13px rgba(0, 128, 0, 0),
      inset 0 0 4px $green-color;
  }
  100% {
    -webkit-box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0),
      inset 0 0 4px $green-color;
  }
}

@keyframes pulseMini {
  0% {
    -moz-box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0.4),
      inset 0 0 4px $green-color;
    box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0.4),
      inset 0 0 4px $green-color;
  }
  70% {
    -moz-box-shadow:
      0 0 0 13px rgba(0, 128, 0, 0),
      inset 0 0 4px $green-color;
    box-shadow:
      0 0 0 13px rgba(0, 128, 0, 0),
      inset 0 0 4px $green-color;
  }
  100% {
    -moz-box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0),
      inset 0 0 4px $green-color;
    box-shadow:
      0 0 0 0 rgba(0, 128, 0, 0),
      inset 0 0 4px $green-color;
  }
}
