@import '../../../../shared/styles/variables.scss';

.crioPrice {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.crioTable__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 4.5vw;
  row-gap: 2vw;
  align-items: center;
  min-width: 300px;

  @media (max-width: $tablet-width) {
    grid-template-columns: 1fr;
  }
}

.crioPrice__wrapper {
  margin-bottom: 3em;
}

.crioPrice__header {
  margin-bottom: 1em;
}

.crioTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 2px solid $green-color;
}

th {
  padding: 0.5em;
  text-align: center;
}

.crioPrice__head,
.crioPrice__td {
  text-align: center;
}

.crioPrice__td,
.crioPrice__str {
  border: 2px solid $green-color;
}

.crioTable__header,
.crioTable__emptyCol {
  background-color: $green-color;
  color: $white-color;
}
