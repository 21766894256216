@import '../../../../shared/styles/variables.scss';

.prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

.prices__header {
  padding: 0.5em;
  text-align: center;
  font-size: 1.5vw;
  width: 75%;

  @media (min-width: 1920px) {
    font-size: 28px;
  }

  @media (max-width: $desktop-width) {
    font-size: 1.1rem;
  }
}

.pricesTable {
  table-layout: fixed;
  width: 60%;
  border-collapse: collapse;
  border: 2px solid $green-color;
  min-width: 300px;

  @media (max-width: $tablet-width) {
    width: 100%;
  }
}

th {
  padding: 0.5em;
  text-align: center;
}

.prices__head,
.prices__td {
  text-align: center;
}

.prices__td,
.prices__str {
  border: 2px solid $green-color;
  padding: 0.5em;
  text-align: center;
}

.prices__header {
  background-color: $green-color;
  color: $white-color;
}

.prices__head {
  width: 100%;
}
