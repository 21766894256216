@import '../../shared/styles/variables.scss';

.orgInfoAbout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3em;
  margin-bottom: 5em;
  width: 90%;
}

.orgInfoAbout__content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3em;
  cursor: default;
  width: 100%;

  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
  }
}

.orgInfoAbout__textContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2em;
}

.orgInfoAbout__imgWrapper {
  @media (max-width: 920px) {
    width: 100%;
    overflow: hidden;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.orgInfoAbout__img {
  width: 35vw;
  max-width: 410px;
  border-radius: 15px;
  box-shadow: 0px 5px 10px 8px $light-gray-color;
  transition: 0.2s;

  &:hover {
    box-shadow: 0px 5px 20px 8px $light-gray-color;
  }

  @media (max-width: 920px) {
    max-width: none;
    width: 100%;
  }
}

.orgInfoAbout__header {
  width: 100%;
  text-align: center;
}
