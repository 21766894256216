@import '../../shared/styles/variables.scss';

.politic {
  width: 80%;
  z-index: 3;

  @media (max-width: 825px) {
    margin-top: 1em;
  }

  @media (max-width: $tablet-width) {
    margin-top: 8em;
  }
}

.politic__header {
  border-bottom: 2px solid $green-color;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
}

.politic__icon {
  width: 25px;
  height: 25px;
  margin-top: -8px;
}

.ol {
  margin: 1em auto 2em;
  list-style: none;
  counter-reset: li;
}

.li {
  margin-bottom: 1em;

  &:before {
    counter-increment: li;
    content: counters(li, '.') '. ';
  }
}
