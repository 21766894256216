@import '../../shared/styles/variables.scss';

.footer {
  background-color: $green-color;
  box-sizing: border-box;
  padding: 1em;
  color: $white-color;
  font-size: 0.8em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  align-items: end;

  a {
    @extend %green-link;
  }
}

.footer__politic {
  text-align: end;
}

.footer__orgInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;
}

.footer__production {
  text-align: end;
}
