@import '../../../shared/styles/variables.scss';

.burgerBtn {
  @media (max-width: $tablet-width) {
    content: '';
    border: none;
    width: 30px;
    height: 2px;
    background-color: $text-color;
    transition: 0.2s;
    border-radius: 25px;
    z-index: 300;
    box-shadow:
      0 8px 0 0px $text-color,
      0 -8px 0 0px $text-color;
  }
}

.burgerBtn_open {
  transform: rotate(45deg);
  box-shadow: none;
  background-color: $white-color;
  position: relative;
  border-radius: 25px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 30px;
    background-color: $white-color;
    transform: rotate(-90deg);
    border-radius: 25px;
    cursor: pointer;
  }
}

.burgerBtn__wrapper {
  display: none;

  @media (max-width: $tablet-width) {
    display: block;
    // position: absolute;
    top: 5px;
    left: 5px;
    width: 45px;
    height: 45px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 300;
  }
}
