@import '../../shared/styles/variables.scss';

.faq {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 3px solid $green-color;
  min-width: 670px;

  @media (max-width: $tablet-width) {
    min-width: 360px;
    border-bottom: 2px solid $green-color;
  }
}

.faq__question {
  font-size: 1.2em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s;
  margin-bottom: 0.5em;
  text-shadow: 0 0 1px $text-color;
  text-align: start;

  span {
    display: block;
    width: 85%;
  }

  &:hover {
    cursor: pointer;
    color: $green-color;

    .togglerBtn {
      cursor: pointer;
      transform: scale(1.1);
    }
    &:active {
      .togglerBtn {
        transform: scale(1);
      }
    }
  }

  @media (max-width: $tablet-width) {
    margin-bottom: 0.7em;
  }
}

.toggler {
  width: 35px;
  background-color: $green-color;
  height: 35px;
  padding: 0.5em;
  border-radius: 50%;

  @media (max-width: $tablet-width) {
    width: 30px;
    height: 30px;
    padding: 0.4em;
  }
}

.rotateToggler {
  transition: 0.2s;
  transform: rotate(45deg);
}

.standartToggler {
  transition: 0.2s;
}

.togglerBtn {
  border: none;
  background: transparent;
  transition: 0.2s;
}

.faq__answer {
  font-size: 1.1em;
  margin-bottom: 1em;
  display: block;
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-out;
  cursor: default;

  @media (max-width: $tablet-width) {
    margin-bottom: 0.5em;
  }
}

.faq__answer_open {
  display: block;
  max-height: 125px;
  transition: max-height 0.45s ease-out;

  @media (max-width: $mobile-width) {
    max-height: 200px;
  }
}
