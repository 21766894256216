@import '../../../shared/styles/variables.scss';

.logo {
  max-width: 250px;
  transition: 0.5s;

  &:hover {
    fill: $green-color;
  }
}

.logo__wrapper {
  display: block;
}
