@import '../../shared/styles/variables.scss';

.serviceListCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  border-radius: 15px;
  position: relative;
  box-shadow: 0 0 15px 1px $light-gray-color;
  font-size: 1.2em;
  transition: 0.2s;
  overflow: hidden;

  max-height: 460px;
  max-width: 700px;
  min-height: 180px;
  min-width: 260px;
  width: 24vw;
  height: 16vw;
  z-index: 5;

  &:hover {
    box-shadow: 0 0 25px 3px $light-gray-color;
    transform: scale(1.02);

    .serviceListCard__header {
      background-color: rgba(45, 154, 58, 0.85);
    }
  }

  @media (max-width: 1200px) {
    font-size: 1em;
  }

  @media (max-width: $desktop-width) {
    border-radius: 8px;
  }

  @media (max-width: $tablet-width) {
    z-index: 2;
  }
}

.serviceListCard__img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  transition: 0.2s;

  max-height: 460px;
  max-width: 700px;
  min-height: 180px;
  min-width: 260px;
  width: 24vw;
  // height: 16vw;
}

.serviceListCard__header {
  z-index: 1;
  width: 100%;
  transition: 0.2s;
  background-color: rgba(45, 154, 58, 0.55);
  backdrop-filter: blur(4px);
  color: $white-color;
  padding: 0.5em;
  font-weight: normal;
  font-size: 1.2em;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  @media (max-width: $desktop-width) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
