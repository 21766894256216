@keyframes main-action-btn {
  0% {
    z-index: 5;
    left: -75px;
  }
  10% {
    z-index: 5;
    left: 120%;
  }
  100% {
    z-index: 5;
    left: 120%;
  }
}

$desktop-width: 1100px;
$tablet-width: 785px;
$mobile-width: 600px;
$desktop-margin-width: 1150px;
$contacts-width: 1000px;

$text-color: #232323;
$white-color: #ffffff;
$green-color: #2d993a;
$gray-color: #686868;
$light-gray-color: #e2e2e2;
$block-color: #fff1d8;

%btn {
  overflow: hidden;
  position: relative;
  background: $green-color;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: $white-color;
  transition: 0.2s;
  min-width: 130px;

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    background: $white-color;
    height: 155px;
    left: -75px;
    opacity: 0.3;
    top: -50px;
    transform: rotate(35deg);
    width: 55px;
    transition: 0.3s;
    animation: main-action-btn 4s ease-in-out infinite;
  }

  &:hover {
    transition: 0.2s;
    cursor: pointer;
    background: $white-color;
    color: $text-color;
    box-shadow: inset 0 0 0 1px $green-color;

    &::after {
      animation: none;
    }
  }
}

%main-action-btn {
  @extend %btn;
  font-size: 1.3em;
  padding: 0.8em 1.5em;
  border-radius: 25px;

  @media (max-width: $desktop-width) {
    font-size: 0.95rem;
  }

  @media (max-width: $tablet-width) {
    font-size: 0.85rem;
  }

  @media (max-width: $mobile-width) {
    font-size: 0.8rem;
  }
}

%link {
  color: $text-color;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 10px;
  transition: 0.2s;
  @media (max-width: 900px) {
    padding: 5px 1vw;
    font-size: 0.95rem;
  }
  @media (min-width: $tablet-width) {
    &:hover {
      transition: 0.2s;
      background-color: $block-color;
    }
  }
}

%green-link {
  color: $white-color;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    text-shadow: 0 0 5px $white-color;
  }
}
