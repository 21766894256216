@import '../../shared/styles/variables.scss';

.div {
  color: #eee;
  font-size: 12px;
  width: 100%;
  height: 70vh;
  max-height: 600px;
  .popup__content {
    display: none;
  }
}

.iframe {
  position: relative;
  border: none;
  width: 100%;
  height: 100%;

  @media (max-width: $contacts-width) {
    height: 75%;
  }
}
