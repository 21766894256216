@import '../../shared/styles/variables.scss';

.price {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2em;
  margin-top: -2em;

  @media (max-width: 900px) {
    margin-top: 0em;
  }

  @media (max-width: $tablet-width) {
    margin-top: 7em;
  }
}
