@import '../../../shared/styles/variables.scss';

.advantages__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;
  width: 90%;
  border-radius: 10px;
  padding: 1em;
  color: $text-color;
  box-shadow: 0px 5px 10px 5px $light-gray-color;
  font-size: 0.8em;
  transition: 0.2s;
  cursor: default;

  &:hover {
    box-shadow: 0px 5px 20px 5px $light-gray-color;
  }

  @media (max-width: $mobile-width) {
    border-radius: 5px;
  }
}

.advantages__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;
}

.advantages__header {
  text-align: start;
}

.advantages__img {
  width: 5vw;
  max-width: 55px;
  min-width: 30px;
}

.advantages__text {
  margin: 0;
}
