@import '../../shared/styles/variables.scss';

.orgInfo__section {
  width: 90%;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    margin-top: 2em;
  }

  @media (max-width: $tablet-width) {
    margin-top: 0em;
    // margin-top: 9em;
  }
}
