@import '../../shared/styles/variables.scss';

.signBlock {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: $green-color;
  margin: 5em;
  padding: 3em 5em;
  cursor: default;
  gap: 1em;
  text-align: start;

  @media (max-width: $mobile-width) {
    font-size: 0.8rem;
  }
}

.signBlock__text {
  font-size: 2em;
  color: $white-color;

  @media (max-width: $desktop-width) {
    font-size: 1.5em;
  }
}

.singBlock__btn {
  @extend %main-action-btn;
  background-color: $white-color;
  color: $text-color;

  svg {
    fill: $green-color;
  }

  &:hover {
    background-color: $green-color;
    box-shadow: inset 0 0 0 1px $white-color;
    color: $white-color;

    svg {
      fill: $white-color !important;
    }
  }
}
