@import '../../shared/styles/variables.scss';

.contacts__section {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  max-height: 515px;

  @media (max-width: $tablet-width) {
    max-height: 830px;
  }

  @media (max-width: $mobile-width) {
    gap: 1em;
  }
}

.contacts__content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    align-items: self-start;
  }

  @media (max-width: $tablet-width) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
