@import '../../shared/styles/variables.scss';

.sliderStocks__section {
  width: 70%;
  position: relative;
  margin-bottom: 5em;
  overflow: hidden;
  margin-top: 2em;

  @media (max-width: $desktop-width) {
    width: 100%;
    margin-top: 0;
  }

  @media (max-width: $tablet-width) {
    margin-top: 3vw;
  }

  @media (max-width: 500px) {
    margin-top: 0;
  }
}
