@import '../../shared/styles/variables.scss';

.stocksCard {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 5px 15px 2px $light-gray-color;
  cursor: default;
  min-height: 300px;

  color: $white-color;
  text-shadow:
    0 0 5px $text-color,
    0 0 10px $green-color;

  @media (max-width: 1300px) {
    height: 430px;
  }

  @media (max-width: $desktop-width) {
    box-shadow: none;
    border-radius: 0;
    height: 450px;
    margin-top: 7em;
  }

  @media (max-width: $tablet-width) {
    height: 400px;
    font-size: 0.8rem;
  }

  @media (max-width: $mobile-width) {
    height: 315px;
  }
}

.stocksCard__img {
  width: 100%;
  min-width: 675px;
  max-width: 1400px;

  @media (max-width: $desktop-width) {
    min-width: 0;
    width: 100%;
  }

  @media (max-width: $mobile-width) {
    min-width: 495px;
  }
}

.stocksCardInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.3em;
  padding: 1em;
  z-index: 5;
  background-color: rgba(0, 128, 0, 0.3);
  box-shadow: 0px 5px 15px 1px #00800093;
  border-radius: 15px;
  width: 42%;
  backdrop-filter: blur(5px);
  margin-left: 1em;
  position: absolute;
  bottom: 15%;

  @media (max-width: $tablet-width) {
    border-radius: 5px;
  }

  @media (max-width: $mobile-width) {
    width: 90%;
    margin-bottom: 1em;
  }
}

.stocksCardInfo__btn {
  @extend %main-action-btn;
}
