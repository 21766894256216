@import '../../shared/styles/variables.scss';

.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.3em;
  padding: 1.5em;
  z-index: 5;
  box-shadow: 0px 5px 15px 3px #008000a9;
  border-radius: 15px;
  width: 20%;
  background-color: $green-color;
  color: $white-color;
  margin-left: 1em;
  position: absolute;
  top: 20%;
  right: 5%;
  min-width: 300px;

  @media (max-width: $desktop-width) {
    min-width: 270px;
  }

  @media (max-width: $tablet-width) {
    min-width: 230px;
  }
}

.contacts_mobile {
  position: static;
  box-shadow: none;
  border-radius: none;
  width: 100%;
  margin: 0;
  background-color: $white-color;
  color: $text-color;
}

.contacts__text {
  color: $white-color;

  @media (max-width: 1000px) {
    color: $text-color;
  }
}

.contacts__text:first-child {
  font-weight: bold;
}

.contactsList {
  list-style: none;
}

.contactsList__itemImg {
  svg {
    max-width: 35px;
    max-height: 35px;
    transition: 0.2s;
    fill: $white-color;

    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: $tablet-width) {
      max-width: 30px;
      max-height: 23;
    }

    @media (max-width: 1000px) {
      fill: $green-color;
    }
  }
}

.contacts__btn {
  @extend %main-action-btn;
  background-color: $white-color;
  color: $text-color;

  svg {
    fill: $green-color;
  }

  &:hover {
    background-color: $green-color;
    box-shadow: inset 0 0 0 1px $white-color;
    color: $white-color;

    svg {
      fill: $white-color !important;
    }
  }

  @media (max-width: 1000px) {
    background-color: $green-color;
    color: $white-color;

    svg {
      fill: $white-color;
    }

    &:hover {
      background-color: $white-color;
      box-shadow: inset 0 0 0 1px $green-color;
      color: $text-color;

      svg {
        fill: $green-color !important;
      }
    }
  }
}
