@import '../../styles/variables.scss';

.button {
  @extend %btn;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  &:hover {
    .buttonIcon__wrapper {
      svg {
        fill: $green-color;
      }
    }
  }
}

.buttonIcon__wrapper {
  display: flex;
  align-items: center;
  left: 5%;
  top: 33%;

  svg {
    transition: 0.2s;
    width: 50%;
    height: 100%;
    fill: $white-color;
    min-width: 15px;
    max-width: 40px;
  }
}
