@import '../../shared/styles/variables.scss';

.serviceCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
  padding: 0 5%;
  margin: 0 auto;
  margin-bottom: 3em;
  border-bottom: 3px solid $green-color;

  @media (max-width: $mobile-width) {
    width: 90%;
  }
}

.serviceCard__mainContentWrapper {
  margin-bottom: 3em;
}

.serviceCard__header {
  margin-bottom: 2em;
  border-bottom: 2px solid $green-color;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;

  @media (max-width: $desktop-width) {
    gap: 1.5vw;
    width: 85%;
  }

  @media (max-width: $mobile-width) {
    width: 100%;
  }
}

.serviceCard__icon {
  width: 25px;
  height: 25px;
  margin-top: -8px;
}

.serviceCard__mainContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2em;
  margin-bottom: 2em;

  @media (max-width: $desktop-width) {
    flex-direction: column;
    align-items: center;
  }
}

.serviceCard__imgWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.serviceCard__img {
  border-radius: 20px;
  box-shadow: 0 0 15px 2px $light-gray-color;
  max-width: 500px;
  width: 33vw;
  min-width: 350px;

  @media (max-width: $desktop-width) {
    border-radius: 10px;
  }

  @media (max-width: $mobile-width) {
    min-width: 270px;
  }
}

.serviceCard__content {
  margin-bottom: 3em;
}

.serviceCardList {
  margin-bottom: 3em;
  list-style: none;
}

.serviceCardList__header {
  margin-bottom: 1em;
  border-bottom: 2px solid $green-color;
  width: 40%;

  @media (max-width: $desktop-width) {
    width: 55%;
  }

  @media (max-width: $tablet-width) {
    width: 60%;
  }

  @media (max-width: $mobile-width) {
    width: 85%;
  }
}

.serviceCardList__item {
  margin-bottom: 1em;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 20%;
    left: -18px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $green-color;
  }
}

.serviceCard__price {
  font-size: 1.8em;
}

.serviceCard__footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1em;
  }
}

.serviceCard__btnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;

  @media (max-width: 540px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1em;
  }
}

.serviceCard__btn {
  @extend %main-action-btn;
}

.serviceCard__priceLink {
  color: $text-color;
  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    color: $green-color;
  }
}

.serviceCard__priceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vw;
  text-align: start;
}
