@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');
@import './styles/normalize.scss';
@import '../shared/styles/variables.scss';

body {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 1.05rem;
  font-weight: 400;
  color: $text-color;
  max-width: 1920px;
  min-width: 390px;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: justify;

  background-color: $white-color;

  a {
    text-decoration: none;
  }

  @media (max-width: $tablet-width) {
    font-size: 0.9rem;
  }
}

#root {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  max-width: 1920px;
  overflow: hidden;
  min-height: 100vh;

  margin: 0 auto;
}

h2 {
  text-align: start;
}

.page {
  width: 100%;
  flex-grow: 1;
}

.visually-hidden {
  visibility: hidden;
  opacity: 0;
  z-index: -1000;

  @media (max-width: $tablet-width) {
    height: 0;
    width: 0;
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  top: -9em;

  @media (max-width: 1100px) {
    top: -7em;
  }
}

.overlay {
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -200;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
}

.overlay_open {
  z-index: 200;
  visibility: visible;
  opacity: 1;
}

.btn-consult {
  min-width: 160px;
}

.d-none {
  display: none;
}
