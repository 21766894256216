@import '../../shared/styles/variables.scss';

.stockWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 5%;
  left: 1%;
  transform: translateX(-150%);
  width: 20vw;
  height: 20vw;
  background-color: $white-color;
  border-radius: 15px;
  max-width: 350px;
  max-height: 300px;
  min-width: 300px;
  min-height: 300px;
  cursor: default;
  box-shadow:
    0 0 15px 5px $light-gray-color,
    inset 0 0 20px 10px $green-color;
  padding: 1em 2em;
  box-sizing: border-box;
  color: $text-color;
  text-shadow:
    0 0 15px $green-color,
    0 0 5px $white-color;
  overflow: hidden;
  transition: 0.8s;
  text-align: center;
  z-index: 5;

  @media (max-width: $tablet-width) {
    min-width: 250px;
    min-height: 250px;
    left: 7%;
    z-index: 4;
  }
}

.stockWindow_open {
  transform: translateX(0%);
}

.stockWindow__img {
  position: absolute;
  width: 20vw;
  height: 20vw;
  z-index: -5;
  top: 0;
  left: 0;
  opacity: 0.6;
  max-width: 350px;
  max-height: 300px;
  min-width: 300px;
  min-height: 300px;

  @media (max-width: $tablet-width) {
    min-width: 250px;
    min-height: 250px;
  }
}

.stockWindow__btn {
  @extend %main-action-btn;
  font-size: 1rem;
}

.stockWindow__closeBtn {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
  font-size: 20px;
}

.stockWindow__header {
  text-align: center;
}
