@import '../../shared/styles/variables.scss';

.specialistCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  gap: 3vw;
  min-height: 280px;
  margin-bottom: 2em;

  @media (max-width: $desktop-width) {
    width: 100%;
  }
}

.specialistCard__imgWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em auto;
}

.specialistCard__img {
  width: 20vw;
  max-width: 350px;
  border-radius: 15px;
  transition: 0.2s;
  box-shadow: 0px 5px 10px 1px $gray-color;
  &:hover {
    box-shadow: 0px 5px 20px 1px $gray-color;
  }

  @media (max-width: $mobile-width) {
    border-radius: 5px;
    min-width: 150px;
    width: 70%;
  }
}

.specialistCard__content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5em;

  @media (max-width: $mobile-width) {
    flex-direction: column;
    align-items: center;
  }
}

.specialistCard__header {
  border-bottom: 2px solid $green-color;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
}

.specialistCard__icon {
  width: 25px;
  height: 25px;
  margin-top: -8px;
}
